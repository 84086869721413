import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["colspan"]
const _hoisted_2 = { colspan: 1 }
const _hoisted_3 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_VBtn = _resolveComponent("VBtn")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    loading: _ctx.loading,
    disabled: _ctx.loading
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("div", { class: "card-title" }, [
            _createElementVNode("div", { class: "card-title-left" }, "Cost Analysis"),
            _createElementVNode("div", { class: "card-title-right" })
          ], -1)
        ])),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "4" }, {
                default: _withCtx(() => [
                  _cache[3] || (_cache[3] = _createElementVNode("label", null, "Select Month", -1)),
                  _createVNode(_component_VueDatePicker, {
                    modelValue: _ctx.selectedMonth,
                    "onUpdate:modelValue": [
                      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedMonth) = $event)),
                      _ctx.fetchCostAnalysis
                    ],
                    clearable: false,
                    range: "",
                    "month-picker": "",
                    position: "center",
                    teleport: ""
                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_data_table_server, {
                    "group-by": _ctx.groupBy,
                    headers: _ctx.headers,
                    items: _ctx.constAnalysises,
                    "item-value": "name",
                    loading: _ctx.loading,
                    page: _ctx.paginationModel.page,
                    "items-per-page": _ctx.paginationModel.numberOfPages,
                    "onUpdate:options": _cache[1] || (_cache[1] = (event) => _ctx.tableOptions = event),
                    "items-length": _ctx.paginationModel.totalItemsCount,
                    "item-key": "Id",
                    density: "",
                    class: "costAnalysis"
                  }, {
                    "group-header": _withCtx(({ item, columns, toggleGroup, isGroupOpen }) => [
                      _createElementVNode("tr", null, [
                        _createElementVNode("td", {
                          colspan: columns.length - 1
                        }, [
                          _createVNode(_component_VBtn, {
                            icon: isGroupOpen(item) ? '$expand' : '$next',
                            size: "small",
                            variant: "text",
                            onClick: ($event: any) => (toggleGroup(item)),
                            class: _normalizeClass([item.depth == 1 ? 'ml-3' : item.depth == 2 ? 'ml-6' : item.depth == 3 ? 'ml-9': ''])
                          }, null, 8, ["icon", "onClick", "class"]),
                          _createTextVNode(" " + _toDisplayString(_ctx.getGroupName(item)), 1)
                        ], 8, _hoisted_1),
                        _createElementVNode("td", _hoisted_2, [
                          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.formatSumOfGroup(item)), 1)
                        ])
                      ])
                    ]),
                    "item.BillableItemFee": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.getBillableItemFee(item)), 1)
                    ]),
                    "item.BillableItemName": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.getBillableItemName(item)), 1)
                    ]),
                    "item.amount": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.getBillableItemAmount(item)), 1)
                    ]),
                    _: 1
                  }, 8, ["group-by", "headers", "items", "loading", "page", "items-per-page", "items-length"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["loading", "disabled"]))
}