<template>
  <v-card>
    <v-card-title>
      <v-row no-gutters>
        VM List
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="mr-4" dark @click="showMaintenancePopup()"> <v-icon>mdi-bell-outline</v-icon> Send Support Announcement</v-btn>
        <v-btn color="primary" dark @click="showDialog = true"> <v-icon>mdi-plus</v-icon> Register new VM</v-btn>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form ref="frmList" lazy-validation>
        <v-btn class="secondary" @click="removeSelection"> <v-icon>mdi-tag-remove-outline</v-icon> Deselect all</v-btn>
        <v-data-table
        :items="vmList"
        :headers="headers"
        :loading="loading"
        :page="page"
        :items-per-page="numberOfPages"
        @update:options="(event: any) => tableOptions = event"
        :items-length="totalItemsCount"
      >
          <template v-slot:item.IsSelected="{ item }">
            <v-checkbox class="vm-checkbox"
                  v-model="item.IsSelected"
                ></v-checkbox>
          </template>
          <template v-slot:item.Teammember="{ item }">
            {{ getTMName(item.OwnerId) }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-menu offset-y location="bottom" scroll-strategy="close">
              <template v-slot:activator="{ props }">
                <v-btn size="small" variant="text" color="black" v-bind="props" icon="mdi-dots-vertical" />
              </template>
              <v-list>
                <v-list-item @click="editVM(item)" prepend-icon="mdi-pencil-outline" title="Edit" />
                <v-list-item @click="showActivityWatch(item)" prepend-icon="mdi-chart-line" title="Show Activity Watch" />
                <v-list-item @click="showWazuhDashboard(item)" prepend-icon="mdi-shield-check" title="Show Wazuh Report" />
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-form>
    </v-card-text>
    <v-dialog width="auto" v-model="showDialog" @keypress.esc="showDialog = false">
      <CreateOrEditVM :teammembers="teammembers" @cancel="cancelRegister" @save="save" :vmModel="selectedVM"></CreateOrEditVM>
    </v-dialog>
    <v-dialog width="600" v-if="showMaintenanceDialog" v-model="showMaintenanceDialog" @keypress.esc="showMaintenanceDialog = false">
      <VdSupport
        :teammembers="teammembers"
        @cancel="cancelRegister"
        @sendRequest="createNotification"
        :vmList="selectedVmList"
      ></VdSupport>
    </v-dialog>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import TeamMemberService from "shared-components/src/services/TeamMemberService";
import { TeamMemberSort, TeamMemberSummarySort } from "shared-components/src/models/Teammember";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";
import AppHelper from "shared-components/src/utils/AppHelper";
import CreateOrEditVM from "./CreateOrEditVM.vue";
import VdSupport from "shared-components/src/components/Vm/VdSupport.vue";
import { VM } from "shared-components/src/models/VM";
import VMService from "shared-components/src/services/VMService";
import { NotificationApi, PaginationRequestModel, UserOfNotification } from "shared-components/src/services/openApi/api";
import { NotificationSection, NotificationType, ProjectMessages } from "shared-components/src/definitions/constants";
import store from "@/store";
import { VMApi } from "shared-components/src/services/openApi";

var vmApi = new VMApi();

export interface VMUIModel extends VM {
  IsSelected: boolean | false;
}

export default defineComponent({
  components: { ConfirmDialog, CreateOrEditVM, VdSupport },
  async mounted() {
    await this.fetchTeammembers();
    await this.fetchVMs();
  },
  data() {
    return {
      showMaintenanceDialog: false,
      tmLoading: true,
      selectedRows: [],
      selectedVmList: [] as VMUIModel[],
      teammembers: [] as TeamMemberSort[] | TeamMemberSummarySort[],
      selectedVM: null as VMUIModel | null,
      loading: false,
      showDialog: false,
      vmList: [] as VMUIModel[],
      tableOptions: {} as any,
      page: 1,
      totalItemsCount: 0 as number | undefined,
      numberOfPages: 10,
      sortProp: "Username",
      sortOrder: "desc",
      headers: [
        { title: "", value: "IsSelected" },
        { title: "Teammember", value: "Teammember", sortable: true },
        { title: "Username", value: "Username", sortable: true },
        { title: "Password", value: "Password", sortable: true },
        { title: "Public IP", value: "PublicIP", sortable: true },
        { title: "Private IP", value: "PrivateIP", sortable: true },
        { title: "Action", value: "action" },
      ],
    };
  },
  computed: {
    isSupport(): boolean {
      return store.getters.userIsSupport ?? false;
    },
  },
  watch:{
    tableOptions(newVal, oldValue) {
      if (oldValue && newVal != oldValue) {
        if (newVal.sortBy && newVal.sortBy.length) {
          this.sortProp = newVal.sortBy[0].key;
        }

        if (newVal.sortDesc && newVal.sortDesc.length) {
          this.sortOrder = newVal.sortDesc[0] ? "desc" : "asc";
        }
        this.fetchVMs();
      }
    },
  },
  methods: {
    removeSelection() {
      this.resetDataTable();
    },
    showMaintenancePopup() {
      this.selectedVmList = this.vmList.filter((c) => c.IsSelected);
      this.showMaintenanceDialog = true;
    },
    createNotification(model: any) {
      var notifStatus = "Pending";
      var requestModel = {
        PushText: "",
        Text: model.Message,
        ShortDesc: model.Title,
        CreationDate: new Date(),
        Section: NotificationSection.Other,
        SmsText: "",
        EmailText: model.Message,
        Users: [] as UserOfNotification[],
        Type: NotificationType.Message,
        SendNow: true,
        Status: notifStatus,
      }
      
      if (model.Receivers) {
        model.Receivers.forEach((c: any) => {
          var userItem = { UserId: c, Status: notifStatus } as UserOfNotification;
          requestModel.Users.push(userItem);
        });
      }
      new NotificationApi().createNotification(requestModel)
        .then((data: any) => {
          store.dispatch("showSuccessMessage", ProjectMessages.vmNotificationSuccessfully);
          this.cancelRegister();
        })
        .catch((error: any) => {
          store.dispatch("showErrorMessage", ProjectMessages.vmNotificationError);
        });
    },
    selectRow(item: any, row: any) {
      var findRow = this.vmList.find((c: any) => c.Id === row.item.Id);
      if (findRow) {
        findRow.IsSelected = !findRow.IsSelected;
        row.isSelected = findRow.IsSelected;
      }
    },
    getTMName(tmId: any) {
      const tm = (this.teammembers as TeamMemberSummarySort[]).find((tm: any) => tm.Id == tmId);
      if (tm) {
        return tm.FullName;
      } else {
        return "";
      }
    },
    cancelRegister() {
      this.showDialog = false;
      this.showMaintenanceDialog = false;
      this.selectedVM = null;

      this.resetDataTable();
    },
    showActivityWatch(item: any) {
      window.location.href = `/vm/activityWatch/${item.Id}`;
    },
    showWazuhDashboard(item: any) {
      window.location.href = `/vm/wazuh/${item.Id}`;
    },
    async fetchTeammembers() {
      try {
        if (AppHelper.IsAzureEnv()) {
          TeamMemberService.getSortedSummaryList()
            .then((items: any) => {
              this.teammembers = items;
              this.tmLoading = false;
              this.teammembers.splice(0, 0, { Id: "", FullName: "All", isFirstInactive: false } as TeamMemberSummarySort);
            })
            .catch((err: any) => this.$emit("errorRaised", err));
        } else {
          TeamMemberService.getSortedList()
            .then((items: any) => {
              this.teammembers = items;
              this.tmLoading = false;
              this.teammembers.splice(0, 0, { Id: "", FullName: "All", isFirstInactive: false } as TeamMemberSort);
            })
            .catch((err: any) => this.$emit("errorRaised", err));
        }
      } catch (ex) {
        this.$emit("errorRaised", ex);
      }
    },
    resetDataTable() {
      this.vmList.forEach((c) => {
        if (c.IsSelected) {
          c.IsSelected = false;
        }
      });
      this.selectedVmList = [] as VMUIModel[];
      (this.$refs.myList as any).selection = {};
    },
    async save(model: VM): Promise<void> {
      try {
        this.loading = true;
        await VMService.save(model);
        store.dispatch("showSuccessMessage", "VM saved successfully");
        await this.fetchVMs();
      } catch (error) {
        store.dispatch("showErrorMessage", "There was an error saving the VM");
        this.loading = false;
      } finally {
        this.showDialog = false;
        this.selectedVM = null;
        this.loading = false;
      }
    },
    async fetchVMs() {
      try {
        this.loading = true;
        const { page, itemsPerPage } = this.tableOptions;
        const paginationModel = {
          PageNumber: page,
          PageSize: itemsPerPage,
          SortOrder: this.sortOrder,
          SortProp: this.sortProp,
        } as PaginationRequestModel;
        
        const result = await vmApi.getVMList(paginationModel);
        if(result.data && result.data.Items){
          this.vmList = [...result.data.Items as any] as VMUIModel[];
        }
      } finally {
        this.loading = false;
      }
    },
    editVM(vmItem: any) {
      this.selectedVM = vmItem;
      this.showDialog = true;
    },
  },
});
</script>
<style lang="scss" scoped>
.selected-row{
  background-color: red;
}
.vm-checkbox{
  height: 50px;
}
.vm-row:hover {
  cursor: pointer;
}
.custom-timesheet-row {
  padding: 10px !important;
  .col {
    overflow-wrap: anywhere;
    text-align: center;
    padding: 0 8px;
  }
  .timesheet-row {
    border: 1px solid #959595;
    border-radius: 10px;
    padding: 10px 0;
    margin: 10px 0;

    .description {
      text-align: left;
      margin: 0 20px;
    }
  }
}
</style>
