<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="12">
          <v-card>
            <v-card-title>
              <span class="headline">Job Openings</span>
              <v-spacer></v-spacer>
              <v-btn color="primary" class="mb-2" @click="newJobPosition()">
                <v-icon left>mdi-plus</v-icon>
                New Job
              </v-btn>
            </v-card-title>
            <v-container class="mt-2">
              <v-row>
                <v-col cols="12" md="6" :xl="isHiringmanager ? 4 : 3" :lg="isHiringmanager ? 4 : 3" sm="6" xs="12">
                  <AutoCompleteField
                    item-title="Name"
                    item-value="id"
                    label="Job Role"
                    :items="jobRoles"
                    v-model="filterJobRole"
                    dense
                    outlined
                    placeholder="Job Role"
                    type="text"
                  ></AutoCompleteField>
                </v-col>
                <v-col cols="12" md="6" :xl="isHiringmanager ? 4 : 3" :lg="isHiringmanager ? 4 : 3" sm="6" xs="12">
                  <AutoCompleteField
                    label="Job Title"
                    v-model="filterJobtitle"
                    :items="jobTitles"
                    item-title="Name"
                    item-value="id"
                    dense
                    outlined
                    placeholder="Job Title"
                    type="text"
                  ></AutoCompleteField>
                </v-col>
                <v-col cols="12" md="6" :xl="isHiringmanager ? 4 : 3" :lg="isHiringmanager ? 4 : 3" sm="6" xs="12">
                  <AutoCompleteField
                    item-title="FullName"
                    item-value="id"
                    :items="customers"
                    label="Customer"
                    v-model="filterCustomer"
                    dense
                    outlined
                    placeholder="Customer"
                    type="text"
                  >
                  </AutoCompleteField>
                </v-col>
                <v-col v-if="!isHiringmanager" cols="12" md="6" :xl="isHiringmanager ? 4 : 3" :lg="isHiringmanager ? 4 : 3" sm="6" xs="12">
                  <AutoCompleteField
                    class="no-wrap-label"
                    :items="hiringManagerList"
                    label="Hiring Manager"
                    v-model="filtereHiringManager"
                    dense
                    outlined
                    placeholder="Hiring Manager"
                    type="text"
                    item-title="fullName"
                    item-value="id"
                  ></AutoCompleteField>
                </v-col>
              </v-row>

              <v-col cols="12" class="filter">
                <v-btn @click="clearFilter">
                  <v-icon left>mdi-close</v-icon>
                  clear Filter
                </v-btn>
                <v-btn color="primary" @click="fitlterJob">
                  <v-icon left>mdi-check</v-icon>
                  Filter
                </v-btn>
              </v-col>
            </v-container>
            <v-data-table-server
              :loading="filterLoading"
              item-key="Id"
              :headers="headers"
              :items="jobs"
              class="elevation-5 pr-5 pl-5"
              :page="page"
              :items-per-page="numberOfPages"
              :items-length="totalItemsCount"
              @update:options="(event: any) => tableOptions = event"
            >
              <template v-slot:item.JobTitles="{ item }">
                {{ getJobTitlesName(item.JobTitles) }}
              </template>
              <template v-slot:item.StartDate="{ item }">
                {{ formatDate(item.StartDate ? new Date(item.StartDate) : null) }}
              </template>

              <template v-slot:item.Status="{ item }">
                <v-chip :color="getStatusColor(item.Status)" dark>
                  {{ item.Status }}
                </v-chip>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-menu offset-y left attach>
                  <template v-slot:activator="{ props }">
                    <v-icon small v-bind="props" @click.stop> mdi-dots-vertical </v-icon>
                  </template>
                  <v-list density="compact">
                    <v-list-item
                      v-for="(actionItem, index) in getDynamicActions(item)"
                      :key="index"
                      @click="actionItem.action(item)"
                      :disabled="(actionItem.text === 'Active' || actionItem.text === 'Archive') && isHiringmanager"
                      color="primary"
                    >
                      <template v-slot:prepend>
                        <v-icon class="mr-1" color="blue" small>{{ actionItem.icon }}</v-icon>
                      </template>
                      <v-list-item-title v-text="actionItem.text" class="text-left"></v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table-server>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog" v-if="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <AddNewJob
          :customers="customers"
          :projectList="projectList"
          :commitmentModel="editItem"
          :hiringManagerList="hiringManagerList"
          :taskList="taskList"
          :skills="skills"
          :details="details"
          :dialog-title="dialogTitle"
          :dialog="dialog"
          :editingJob="editingJob"
          @PositionSaved="positonSaved"
          @close-dialog="closeDialog"
        />
      </v-dialog>

      <v-dialog v-model="viewDialog" v-if="viewDialog" max-width="1000px" persistent transition="dialog-bottom-transition">
        <DetailJobOpening
          :job="selectedJob"
          @close-dialog="closeDetailDialog"
          :selectedCutomerName="selectedCutomerName"
          :selectedJobRole="selectedJobRole"
        />
      </v-dialog>
    </v-container>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AddNewJob from "./AddNewJob.vue";
import {
  PositionApi,
  PositionModel,
  PositionResponseModel,
  TaskTemplateApi,
  TaskTemplateModel,
  UserApi,
  PaginationRequestModel,
  PositionDetailResponseModel,
} from "shared-components/src/services/openApi/api";
import Details from "shared-components/src/models/Details";
import DetailsService from "shared-components/src/services/DetailsService";
import ApiService from "@/services/ApiService";
import CoreSkill from "shared-components/src/models/CoreSkill";
import CoreSkillService from "@/services/CoreSkillService";
import DetailJobOpening from "./DetailJobOpening.vue";
import { ProjectApi } from "shared-components/src/services/openApi";
import CustomerService from "shared-components/src/services/CustomerService";
import ManagerModel from "shared-components/src/models/ManagerModel";
import Project from "shared-components/src/models/Project";
import Utils from "shared-components/src/utils/Utils";
import store from "@/store";

var positionApi = new PositionApi();
var taskTemplateApi = new TaskTemplateApi();
var userApi = new UserApi();

export default defineComponent({
  components: {
    AddNewJob,
    DetailJobOpening,
  },
  data() {
    return {
      filterLoading: true,
      selectedJobRole: "",
      selectedCutomerName: "",
      jobRoles: [] as Details[],
      jobTitles: [] as Details[],
      projectList: [] as Project[],
      customers: [] as ManagerModel[],
      editItem: null as any,
      desserts: [] as any,
      hiringManagerList: [] as any,
      taskList: [] as TaskTemplateModel[],
      skills: [] as CoreSkill[],
      details: [] as Details[],
      dialog: false,
      viewDialog: false,
      headers: [
        { title: "Title", align: "start", value: "Title", sortable: false },
        { title: "Job Role", align: "center", value: "JobRole", sortable: false },
        { title: "Job Title", align: "center", value: "JobTitles", sortable: false },
        { title: "Date", align: "center", value: "StartDate", sortable: false },
        { title: "Hiring Manager", align: "center", value: "HiringmanagerName", sortable: false },
        { title: "Customer", align: "center", value: "CustomerName", sortable: false },
        { title: "Status", align: "center", value: "Status", sortable: false },
        { title: "Actions", align: "end", value: "actions", sortable: false },
      ],
      actions: [
        { text: "Edit", icon: "mdi-pencil", action: this.editingItem },
        { text: "View", icon: "mdi-tooltip-text", action: this.viewItems },
        { text: "Archive", icon: "mdi-delete", action: this.changeStatus },
      ],
      jobs: [] as PositionResponseModel[],
      selectedJob: {} as PositionDetailResponseModel,
      editingJob: null as PositionDetailResponseModel | null,
      dialogTitle: "",
      actionNumber: 0,
      tableOptions: {} as any,
      totalItemsCount: 0 as number | undefined,
      sortOrder: "desc",
      sortProp: "Title",
      numberOfPages: 10,
      page: 1,
      filterJobRole: "",
      filterJobtitle: "",
      filterCustomer: "",
      filtereHiringManager: "",
      filters: {} as any,
    };
  },
  async mounted() {
    await this.fetchProjects();
    await this.fetchCustomers();
    this.getHiringManagerList();
    this.getPositionData();
    this.getTaskList();
    await this.loadDetailsList();
    await this.loadSkillsList();
  },
  computed: {
    isHiringmanager(): boolean {
      return store.getters.userIsHiringmanager ?? false;
    },
  },
  watch: {
    tableOptions(newVal, oldValue) {
      if (oldValue && newVal != oldValue) {
        if (newVal.sortBy && newVal.sortBy.length) {
          this.sortProp = newVal.sortBy[0];
        }
        if (newVal.sortDesc && newVal.sortDesc.length) {
          this.sortOrder = newVal.sortDesc[0] ? "desc" : "Title";
        }
      }
      this.getPositionData();
    },
    details(newVal: any) {
      this.getJobRoleAndJobTitle();
    },
    filterJobRole(newVal: any) {
      this.filterJobTitleByRole();
      if (newVal) {
        this.filters["JobRoleId"] = newVal;
      } else {
        delete this.filters["JobRoleId"];
      }
    },
    filtereHiringManager(newVal) {
      if (newVal) {
        this.filters["HiringManagerId"] = newVal;
      } else {
        delete this.filters["HiringManagerId"];
      }
    },
    filterJobtitle(newVal) {
      if (newVal) {
        this.filters["JobTitle"] = newVal;
      } else {
        delete this.filters["JobTitle"];
      }
    },
    filterCustomer(newVal) {
      if (newVal) {
        this.filters["Project.CustomerId"] = newVal;
      } else {
        delete this.filters["Project.CustomerId"];
      }
    },
  },
  methods: {
    getJobRoleAndJobTitle() {
      if (!this.details) {
        return;
      }
      this.jobRoles = this.details.filter((item: any) => item.Type === "JobRole");

      this.filterJobtitle = "";
      if (this.filterJobRole) {
        this.jobTitles = this.details.filter((item: any) => item.Type === "JobTitle" && item.RoleId === this.filterJobRole);
      } else {
        this.jobTitles = [];
      }
    },
    filterJobTitleByRole() {
      if (!this.details) {
        return;
      }

      this.filterJobtitle = "";

      this.jobTitles = this.details.filter((item: any) => item.Type === "JobTitle" && item.RoleId === this.filterJobRole);
    },
    formatDate(date: Date | null): string {
      if (date) {
        return Utils.toVsDateFormat(date);
      }
      return "";
    },
    getJobTitlesName(item: string[] | undefined): string {
      if (item == null) {
        console.warn("Job Titles is null or undefined");
        return "Job Title is null";
      }

      if (item.length === 0) {
        console.warn("Job Titles array is empty");
        return "No Job Title";
      }

      const result = item.join(",");
      return result;
    },
    async loadSkillsList() {
      this.skills = await CoreSkillService.getList();
    },
    async changeStatus(item: PositionResponseModel | null) {
      this.filterLoading = true;
      if (!item?.Id || !item?.Status) {
        throw new Error("Both id and status are required parameters.");
      }

      const newStatus = await positionApi.changePositionStatus(item.Id, item.Status);
      this.filterLoading = false;
      let updatedStatus = "";
      if (newStatus.data.Status === "Active") {
        updatedStatus = "Archived";
      } else if (newStatus.data.Status === "Archived") {
        updatedStatus = "Active";
      }

      if (updatedStatus) {
        const updatedResponse = await positionApi.changePositionStatus(item.Id, updatedStatus);

        const jobIndex = this.jobs.findIndex((job) => job.Id === item.Id);
        if (jobIndex !== -1) {
          this.jobs[jobIndex].Status = updatedStatus;
        }
      }
    },
    getStatusColor(status: string | undefined) {
      if (status === "Active") {
        return "green";
      } else if (status === "Archived") {
        return "gray";
      } else {
        return "default";
      }
    },
    getDynamicActions(item: any) {
      return [
        { text: "Edit", icon: "mdi-pencil", action: this.editingItem },
        { text: "View", icon: "mdi-tooltip-text", action: this.viewItems },
        {
          text: item.Status === "Active" ? "Archive" : "Active",
          icon: "mdi-delete",
          action: this.changeStatus,
        },
      ];
    },
    async loadDetailsList() {
      this.details = await DetailsService.getDetails(ApiService);
    },
    async getHiringManagerList() {
      if (!this.isHiringmanager) {
        try {
          const response = await userApi.getUsersByRole("hiringmanager");
          this.hiringManagerList = response.data.map((manager: any) => ({
            id: manager.id,
            fullName: manager.name,
          }));
        } catch (error) {
          console.error("Error fetching hiring managers:", error);
        }
      }
    },
    async getTaskList() {
      if (!this.isHiringmanager) {
        await taskTemplateApi.geTaskTemplateList().then((data: any) => {
          this.taskList = data.data;
        });
      }
    },
    async getPositionData() {
      this.filterLoading = true;
      try {
        const { page, itemsPerPage } = this.tableOptions;

        const model = {
          PageNumber: page,
          PageSize: itemsPerPage,
          SortOrder: this.sortOrder,
          SortProp: this.sortProp,
        } as PaginationRequestModel;
        const result = await positionApi.getPositionList(model);
        this.jobs = result.data.Items as PositionResponseModel[];
        this.totalItemsCount = result.data.Total;
        this.filterLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    openDialog() {
      this.dialog = true;
      switch (this.actionNumber) {
        case 1:
          this.dialogTitle = "Edit Job";
          break;
        case 2:
          this.dialogTitle = "View Job";
          break;
        case 3:
          this.dialogTitle = "New Job";
          break;
      }
    },
    newJobPosition() {
      this.dialog = true;
      this.actionNumber = 3;
      this.editingJob = null;
      this.openDialog();
    },
    closeDialog() {
      this.dialog = false;
    },
    positonSaved(response: PositionResponseModel) {
      const finededIndex = this.jobs.findIndex((item) => item.Id == response.Id);

      if (finededIndex == -1) {
        this.jobs.unshift(response);
      } else {
        this.jobs.splice(finededIndex, 1, response);
      }

      this.closeDialog();
    },
    async viewItems(item: PositionResponseModel) {
      this.filterLoading = true;
      if (item?.Id) {
        const jobDetail = await positionApi.getPositionDetial(item?.Id);
        this.filterLoading = false;
        this.selectedJob = jobDetail.data as PositionDetailResponseModel;
        this.selectedCutomerName = item.CustomerName || "";
        this.selectedJobRole = item.JobRole || "";
        this.viewDialog = true;
      }
    },
    closeDetailDialog() {
      this.viewDialog = false;
    },
    async editingItem(item: PositionModel | null) {
      this.filterLoading = true;
      try {
        if (item?.Id) {
          const jobDetail = await positionApi.getPositionDetial(item?.Id);
          this.editingJob = { ...(jobDetail.data as PositionDetailResponseModel) };
          this.filterLoading = false;
        }
      } catch (err) {
        console.error(err);
      }
      this.actionNumber = 1;
      this.dialogTitle = "Edit Job";
      this.dialog = true;
    },
    async fetchProjects() {
      if (!this.isHiringmanager) {
        try {
          new ProjectApi()
            .getOnlyProjects()
            .then((items: any) => {
              this.projectList = items.data;
            })
            .catch((err: any) => this.$emit("errorRaised", err));
        } catch (ex) {
          this.$emit("errorRaised", ex);
        }
      }
    },
    async fetchCustomers() {
      try {
        const items = await CustomerService.getList(true);
        this.customers = items;
      } catch (err) {
        this.$emit("errorRaised", err);
      }
    },
    async fitlterJob() {
      try {
        const { page, itemsPerPage } = this.tableOptions;

        var s = {
          pageNumber: page,
          pageSize: itemsPerPage,
          SortOrder: this.sortOrder,
          SortProp: this.sortProp,
          Filters: this.filters,
        } as PaginationRequestModel;
        const result = await positionApi.getPositionList(s);
        this.jobs = result.data.Items as PositionResponseModel[];
        this.filterLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    clearFilter() {
      this.filterCustomer = "";
      this.filterJobRole = "";
      this.filterJobtitle = "";
      this.filtereHiringManager = "";

      this.getPositionData();
    },
  },
});
</script>

<style scoped>
.headline {
  font-weight: bold;
  padding-left: 8px;
}
.v-card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.v-data-table {
  margin-top: 16px;
}
.filter {
  gap: 8px;
  display: flex;
  justify-content: flex-end;
}
</style>
