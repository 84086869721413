<template>
  <div>
    <v-card>
      <v-card-title>
        Expense Analysis
        <v-divider class="mx-4" inset vertical></v-divider>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="3" sm="6" cols="12" v-show="reportType === 'Forecast'">
            <label class="input-label">Date</label>
            <VueDatePicker v-model="selectedMonth" month-picker :min-date="getMinForecastDate()" auto-apply />
          </v-col>
          <v-col md="3" sm="6" cols="12" v-show="reportType !== 'Forecast'">
            <label class="input-label">Date</label>
            <VueDatePicker teleport v-model="selectedDateRange" :max-date="new Date()" range auto-apply :enable-time-picker="false" />
          </v-col>

          <v-col md="3" sm="6" cols="12">
            <ComboBoxField dense v-model="selectedTeammember" :loading="tmLoading" :disabled="tmLoading"
              :items="teammemberSortList" item-title="FullName" item-value="Id" outlined label="Teammember">
              <template v-slot:item="{ item, props }">
                <v-list>
                  <v-list-item v-bind="props" :class="{ 'txt-color': item.raw.isFirstInactive }"
                    :title="item.raw.FullName"></v-list-item>
                </v-list>
              </template>
            </ComboBoxField>
          </v-col>

          <v-col md="2" sm="6" cols="12">
            <v-radio-group class="mt-0" v-model="reportType" row>
              <v-radio label="Actual" value="Actual"></v-radio>
              <v-radio label="Forecast" value="Forecast"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col md="2" sm="4" cols="12">
            <v-btn class="accent action-button" :loading="loading" @click="createReport"> <v-icon>mdi-sync</v-icon>
              Process
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <v-btn v-for="item in filteredExpenseAnalysis.TotalPaymentUnit" :key="item.Unit" class="ma-3">
              {{ formatNumber(item.Value) }} {{ item.Unit }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" sm="6" cols="12" v-for="item in filteredExpenseAnalysis.PayrunDetailByTeammember"
            :key="item.TeammemberId">
            <v-card class="teammember-payrun-card">
              <v-card-title>{{ getTeammemberName(item.TeammemberId) }}</v-card-title>
              <v-card-text>
                <v-col class="pl-0 pr-0" v-for="monthly in item.PayrunDetailByMonth" :key="monthly.MonthNumber">
                  <v-row>
                    <v-col md="4" sm="4" cols="12" class="pt-6"><span class="font-weight-bold">{{
                      getMonthNameByNumber(monthly.MonthNumber) }}</span></v-col>
                    <v-col md="4" sm="4" cols="12" class="pl-0 pr-0">
                      <v-col v-for="basePayment in monthly.BasePaymentUnit" :key="basePayment">
                        {{ formatNumber(basePayment.Value) }} <span class="font-weight-bold">{{ basePayment.Unit
                          }}</span>
                      </v-col>
                    </v-col>
                    <v-col md="4" sm="4" cols="12" class="pl-0 pr-0">
                      <v-col v-for="convertedPaymentUnit in monthly.ConvertedPaymentUnit" :key="convertedPaymentUnit">
                        {{ formatNumber(convertedPaymentUnit.Value) }} <span class="font-weight-bold">{{
                          convertedPaymentUnit.Unit }}</span>
                      </v-col>
                    </v-col>
                  </v-row>
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import moment from "moment";
import Utils from "shared-components/src/utils/Utils";
import PayRunService from "shared-components/src/services/PayRunService";
import TeamMemberService from "shared-components/src/services/TeamMemberService";
import ExpenseAnalysis from "shared-components/src/models/ExpenseAnalysis";
import { TeamMemberSort, TeamMemberSummarySort } from "shared-components/src/models/Teammember";
import { DateRange } from "shared-components/src/definitions/constants";
import { Profile } from "shared-components/src/definitions/config";
import AppHelper from "shared-components/src/utils/AppHelper";
import AutoCompleteField from "shared-components/src/components/Fields/AutoCompleteField.vue";

// ExpenseAnalysisReportType
export default defineComponent({
  data() {
    return {
      selectedMonth: {
        month: this.getMinForecastDate().getMonth(),
        year: this.getMinForecastDate().getFullYear(),
      },
      loading: false,
      reportType: "",
      expenseAnalysis: {} as ExpenseAnalysis,
      filteredExpenseAnalysis: {} as ExpenseAnalysis,
      teammemberSortList: [] as TeamMemberSort[] | TeamMemberSummarySort[],
      selectedTeammember: null as TeamMemberSort | TeamMemberSummarySort | null,
      fromDate: new Date() as Date,
      toDate: new Date() as Date,
      tmLoading: true,
      pageLoading: false,
      selectedDateRange: [],
      datePickerMenu: false,
      datePickerMenu1: false,
      dateRanges: DateRange,
    };
  },
  computed: {
    minDate(): string {
      var todayDate = new Date();
      return Utils.toVsDateFormat(todayDate);
    },
    selectedDate: {
      get(): Date {
        return this.fromDate
      },
      set(date: string): void {
        this.setFromDateAndToDate(date);
      },
    },
    selectedDateText: {
      get(): string {
        return Utils.getMonthName(this.toDate);
      },
      set(date: string): void {
      },
    },
    dateRangeText(): string {
      const retVal = `${Utils.toVsDateFormat(this.selectedDateRange[0])} ~ ${Utils.toVsDateFormat(this.selectedDateRange[this.selectedDateRange.length - 1])}`;
      if (this.selectedDateRange.length > 1) {
        this.datePickerMenu = false;
        this.datePickerMenu1 = false;
      }
      return retVal;
    },
    startDate(): string {
      return this.selectedDateRange[0];
    },
    endDate(): string {
      return this.selectedDateRange[this.selectedDateRange.length - 1];
    },
  },
  mounted() {
    this.reportType = "Actual";
    this.initTeamMemberSortList();
    var forecastMinDate = this.getMinForecastDate();
    this.setFromDateAndToDate(forecastMinDate);
  },
  watch: {
    selectedMonth(newValue) {
      if (newValue) {
        this.setFromDateAndToDate(`${newValue.year}-${newValue.month + 1}`);
      }
    },
  },
  methods: {
    getMinForecastDate(): Date {
      var date = new Date()
      return new Date(date.getFullYear(), date.getMonth() + 1, 1);
    },
    formatNumber(value: number): string {
      return Utils.moneyFormat(value);
    },
    setFromDateAndToDate(date: string | Date) {
      // set from date
      if (typeof date === "string") {
        this.fromDate = date ? Utils.vsDateToDatetime(`${date}-01`) : Utils.vsDateToDatetime(new Date().toDateString());
      } else {
        this.fromDate = Utils.firstDayOfMonth(date);
      }
      // set to date
      this.toDate = Utils.lastOfTime(Utils.lastDayOfMonth(this.fromDate));
    },
    getMonthNameByNumber(monthNumber: any) {
      if (monthNumber) {
        return Utils.getMonthName(monthNumber - 1);
      }
    },
    getTeammemberName(id: any) {
      var findTeammember = (this.teammemberSortList as any).find((c: any) => c.Id === id);
      if (findTeammember) {
        return findTeammember.FullName;
      }
    },
    initTeamMemberSortList() {
      if (AppHelper.IsAzureEnv()) {
        TeamMemberService.getSortedSummaryList().then((res: any) => {
          this.teammemberSortList = res;
          var allSelect = { Id: "", FullName: "All" } as TeamMemberSummarySort;
          this.teammemberSortList.splice(0, 0, allSelect);
          this.selectedTeammember = allSelect;
          this.tmLoading = false;
          this.pageLoading = false;
        });
      } else {
        TeamMemberService.getSortedList().then((res: any) => {
          this.teammemberSortList = res;
          var allSelect = { Id: "", FullName: "All" } as TeamMemberSort;
          this.teammemberSortList.splice(0, 0, allSelect);
          this.selectedTeammember = allSelect;
          this.tmLoading = false;
          this.pageLoading = false;
        });
      }
    },
    async createReport() {
      var sDate = Utils.vsDateToDatetime(this.startDate);
      var eDate = Utils.vsDateToDatetime(this.endDate);
      var analysis = null as ExpenseAnalysis | null;
      var tmId = this.selectedTeammember?.Id ? this.selectedTeammember?.Id : "";
      if (this.reportType === Profile.Members.ExpenseAnalysisReportType.Forecast) {
        this.loading = true;
        this.toDate = Utils.lastOfTime(Utils.lastDayOfMonth(this.fromDate));
        analysis = await PayRunService.GetForecastExpenseAnalysis(this.fromDate, this.toDate, tmId);
      } else {
        if (this.startDate && this.endDate) {
          this.loading = true;
          analysis = await PayRunService.GetActualExpenseAnalysis(sDate, eDate, tmId);
        }
      }
      if (analysis) {
        this.expenseAnalysis = analysis;
        this.filteredExpenseAnalysis = { ...analysis };
      }
      this.loading = false;
    },
    selectedRange(newVal: string) {
      let startDate = new Date();
      let endDate = new Date();
      switch (newVal) {
        case "This Week":
          startDate = moment().startOf("week").toDate();
          endDate = moment().endOf("week").toDate();
          break;
        case "Last Week":
          startDate = moment().subtract(1, "weeks").startOf("week").toDate();
          endDate = moment().subtract(1, "weeks").endOf("week").toDate();
          break;
        case "Last Month":
          startDate = moment().subtract(1, "months").startOf("month").toDate();
          endDate = moment().subtract(1, "months").endOf("month").toDate();
          break;
        case "This Month":
          startDate = moment().startOf("month").toDate();
          endDate = moment().endOf("month").toDate();
          break;
        case "Last Year":
          startDate = moment().subtract(1, "years").startOf("year").toDate();
          endDate = moment().subtract(1, "years").endOf("year").toDate();
          break;
        case "This Year":
          startDate = moment().startOf("year").toDate();
          endDate = moment().endOf("year").toDate();
          break;
      }
      if (startDate < endDate) {
        (this.selectedDateRange as any) = [startDate, endDate];
      }
    }
  },
});
</script>
<style scoped>
.teammember-payrun-card {
  max-height: 150px;
  overflow-y: scroll;
}
</style>
