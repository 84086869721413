import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_ComboBoxField = _resolveComponent("ComboBoxField")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_DateInputField = _resolveComponent("DateInputField")!
  const _component_TextAreaField = _resolveComponent("TextAreaField")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_toolbar, {
        dark: "",
        color: "primary",
        class: "sticky-title"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.dialogTitle), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_btn, {
            icon: "",
            dark: "",
            onClick: _ctx.closeDialog,
            class: "mb-5"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => _cache[18] || (_cache[18] = [
                  _createTextVNode("mdi-close")
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, {
            class: "form-inputs",
            ref: "frmPosition"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_container, { class: "container" }, {
                default: _withCtx(() => [
                  (!_ctx.isHiringmanager)
                    ? (_openBlock(), _createBlock(_component_v_card, {
                        key: 0,
                        class: "postiion",
                        disabled: _ctx.loading,
                        loading: _ctx.loading
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_card_title, null, {
                                    default: _withCtx(() => _cache[19] || (_cache[19] = [
                                      _createTextVNode("Job Opening")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                md: "6",
                                xs: "12",
                                sm: "12"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_TextField, {
                                    placeholder: "Title",
                                    showRequired: "",
                                    rules: [_ctx.validations.required],
                                    dense: "",
                                    modelValue: _ctx.createPositionModel.Title,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.createPositionModel.Title) = $event)),
                                    label: "Title",
                                    outlined: ""
                                  }, null, 8, ["rules", "modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                md: "6",
                                xs: "12",
                                sm: "12"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ComboBoxField, {
                                    rules: [_ctx.validations.required],
                                    placeholder: "Customer",
                                    showRequired: "",
                                    items: _ctx.fetchCustomers,
                                    "item-title": "FullName",
                                    "item-value": "id",
                                    label: "Customer",
                                    outlined: "",
                                    dense: "",
                                    modelValue: _ctx.selectedCustomers,
                                    "onUpdate:modelValue": [
                                      _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedCustomers) = $event)),
                                      _ctx.onChangeCustomer
                                    ]
                                  }, null, 8, ["rules", "items", "modelValue", "onUpdate:modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                md: "6",
                                xs: "12",
                                sm: "12"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_AutoCompleteField, {
                                    rules: [_ctx.validations.required],
                                    placeholder: "Project",
                                    showRequired: "",
                                    items: _ctx.filteredProjects,
                                    "item-title": "Name",
                                    "item-value": "id",
                                    label: "Project",
                                    outlined: "",
                                    dense: "",
                                    modelValue: _ctx.createPositionModel.ProjectId,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.createPositionModel.ProjectId) = $event))
                                  }, null, 8, ["rules", "items", "modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                md: "6",
                                xs: "12",
                                sm: "12"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_AutoCompleteField, {
                                    rules: [_ctx.validations.required],
                                    dense: "",
                                    label: "Job Role",
                                    placeholder: "Job Role",
                                    "item-title": "Name",
                                    "item-value": "id",
                                    showRequired: "",
                                    items: _ctx.jobRoles,
                                    modelValue: _ctx.selectedJobRole,
                                    "onUpdate:modelValue": [
                                      _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedJobRole) = $event)),
                                      _ctx.onChangeJobRole
                                    ],
                                    outlined: ""
                                  }, null, 8, ["rules", "items", "modelValue", "onUpdate:modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                md: "6",
                                xs: "12",
                                sm: "12"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_AutoCompleteField, {
                                    rules: [_ctx.validations.required],
                                    id: "jobTitle",
                                    clearable: "",
                                    label: "Job Title",
                                    placeholder: "Job Title",
                                    items: _ctx.jobTitles,
                                    outlined: "",
                                    dense: "",
                                    multiple: "",
                                    showRequired: "",
                                    "item-title": "Name",
                                    "item-value": "id",
                                    "closable-chips": "",
                                    modelValue: _ctx.selectedJobTitle,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedJobTitle) = $event))
                                  }, null, 8, ["rules", "items", "modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                md: "6",
                                xs: "12",
                                sm: "12"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_AutoCompleteField, {
                                    rules: [_ctx.validations.required],
                                    dense: "",
                                    label: "Hiring Manager",
                                    placeholder: "Hiring Manager",
                                    showRequired: "",
                                    modelValue: _ctx.createPositionModel.HiringManagerId,
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.createPositionModel.HiringManagerId) = $event)),
                                    items: _ctx.hiringManagerList,
                                    "item-title": "fullName",
                                    "item-value": "id",
                                    outlined: ""
                                  }, null, 8, ["rules", "modelValue", "items"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                md: "6",
                                xs: "12",
                                sm: "12"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ComboBoxField, {
                                    rules: [_ctx.validations.EmailCheck, _ctx.validations.required],
                                    modelValue: _ctx.createPositionModel.HiringContact,
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.createPositionModel.HiringContact) = $event)),
                                    dense: "",
                                    outlined: "",
                                    label: "Hiring Contact",
                                    showRequired: "",
                                    placeholder: "Hiring Contact",
                                    type: "text"
                                  }, null, 8, ["rules", "modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                md: "6",
                                xs: "12",
                                sm: "12"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_TextField, {
                                    rules: [_ctx.validations.required, _ctx.validations.integer],
                                    dense: "",
                                    placeholder: "Number of Positions",
                                    label: "Number of Positions",
                                    showRequired: "",
                                    modelValue: _ctx.createPositionModel.NoOfPositions,
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.createPositionModel.NoOfPositions) = $event)),
                                    type: "number",
                                    outlined: ""
                                  }, null, 8, ["rules", "modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                md: "6",
                                xs: "12",
                                sm: "12"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_DateInputField, {
                                    showRequired: "",
                                    label: "Position Start Date",
                                    "first-day-of-week": "1",
                                    rules: [_ctx.validations.required],
                                    modelValue: _ctx.createPositionModel.StartDate,
                                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.createPositionModel.StartDate) = $event)),
                                    "hide-actions": "",
                                    placeholder: "Date",
                                    "prepend-icon": ""
                                  }, null, 8, ["rules", "modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                md: "6",
                                xs: "12",
                                sm: "12"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_AutoCompleteField, {
                                    rules: [_ctx.validations.required],
                                    id: "country",
                                    dense: "",
                                    label: "Countries",
                                    placeholder: "Countries",
                                    showRequired: "",
                                    modelValue: _ctx.createPositionModel.Country,
                                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.createPositionModel.Country) = $event)),
                                    items: _ctx.sortedLocations,
                                    multiple: "",
                                    outlined: "",
                                    "item-title": "text",
                                    "item-value": "value"
                                  }, null, 8, ["rules", "modelValue", "items"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                md: "6",
                                xs: "12",
                                sm: "12"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_AutoCompleteField, {
                                    rules: [_ctx.validations.required],
                                    multiple: "",
                                    dense: "",
                                    label: "Skills",
                                    placeholder: "Skills",
                                    showRequired: "",
                                    modelValue: _ctx.createPositionModel.Skills,
                                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.createPositionModel.Skills) = $event)),
                                    outlined: "",
                                    items: _ctx.sortedSkills,
                                    "item-title": "Text",
                                    "item-value": "id"
                                  }, null, 8, ["rules", "modelValue", "items"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_TextAreaField, {
                                    rules: [_ctx.validations.required],
                                    showRequired: "",
                                    modelValue: _ctx.createPositionModel.JobDescription,
                                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.createPositionModel.JobDescription) = $event)),
                                    outlined: "",
                                    label: "Job Description",
                                    placeholder: "Job Description"
                                  }, null, 8, ["rules", "modelValue"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled", "loading"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_form, { ref: "frmTask" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, { class: "postiion" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { cols: "12" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_card_title, null, {
                                        default: _withCtx(() => _cache[20] || (_cache[20] = [
                                          _createTextVNode("Tasks")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "6",
                                    xs: "12",
                                    sm: "12"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ComboBoxField, {
                                        rules: [_ctx.validations.required],
                                        id: "Task",
                                        clearable: "",
                                        label: "Task",
                                        placeholder: "Task",
                                        showRequired: "",
                                        outlined: "",
                                        items: _ctx.taskList,
                                        dense: "",
                                        "item-title": "Title",
                                        "item-value": "Id",
                                        "onUpdate:modelValue": [
                                          _ctx.onTaskTemplateSelected,
                                          _cache[12] || (_cache[12] = ($event: any) => ((_ctx.selectedTask) = $event))
                                        ],
                                        modelValue: _ctx.selectedTask,
                                        "return-object": false
                                      }, null, 8, ["rules", "items", "onUpdate:modelValue", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    class: "mt-8",
                                    cols: "12",
                                    md: "4",
                                    xs: "12",
                                    sm: "12"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_switch, {
                                        disabled: _ctx.disableSwitch,
                                        modelValue: _ctx.newTask.isDefault,
                                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.newTask.isDefault) = $event)),
                                        label: "Add to task",
                                        color: "primary"
                                      }, null, 8, ["disabled", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "12" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_TextAreaField, {
                                        rules: [_ctx.validations.required],
                                        showRequired: "",
                                        modelValue: _ctx.newTask.Description,
                                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.newTask.Description) = $event)),
                                        outlined: "",
                                        label: "Description",
                                        placeholder: "Description"
                                      }, null, 8, ["rules", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: 6,
                                    xs: "12",
                                    sm: "12"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_DateInputField, {
                                        showRequired: "",
                                        label: "Date",
                                        "first-day-of-week": "1",
                                        rules: [_ctx.validations.required],
                                        modelValue: _ctx.selectedTaskDate,
                                        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.selectedTaskDate) = $event)),
                                        "hide-actions": "",
                                        placeholder: "Date",
                                        "prepend-icon": ""
                                      }, null, 8, ["rules", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: 6,
                                    xs: "12",
                                    sm: "12"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_TextField, {
                                        rules: [_ctx.validations.required, _ctx.validations.integer],
                                        dense: "",
                                        outlined: "",
                                        label: "Priority",
                                        modelValue: _ctx.newTask.OrderNumber,
                                        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.newTask.OrderNumber) = $event)),
                                        showRequired: "",
                                        placeholder: "Priority",
                                        type: "number"
                                      }, null, 8, ["rules", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    class: "save-btn mb-5"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_btn, {
                                        class: "secondary",
                                        variant: "elevated",
                                        onClick: _ctx.resettaskform
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_icon, null, {
                                            default: _withCtx(() => _cache[21] || (_cache[21] = [
                                              _createTextVNode("mdi-close")
                                            ])),
                                            _: 1
                                          }),
                                          _cache[22] || (_cache[22] = _createTextVNode(" cancel"))
                                        ]),
                                        _: 1
                                      }, 8, ["onClick"]),
                                      _createVNode(_component_v_btn, {
                                        color: "primary",
                                        onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.isEditingTask ? _ctx.saveEditedTask() : _ctx.addTask()))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_icon, { left: "" }, {
                                            default: _withCtx(() => _cache[23] || (_cache[23] = [
                                              _createTextVNode("mdi-check")
                                            ])),
                                            _: 1
                                          }),
                                          _createTextVNode(" " + _toDisplayString(_ctx.isEditingTask ? "Update" : "Add Task"), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_card, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_data_table, {
                                        headers: _ctx.headerTask,
                                        items: _ctx.tableTasks,
                                        "hide-default-footer": true
                                      }, {
                                        "item.TaskTemplateName": _withCtx(({ item }) => [
                                          _createTextVNode(_toDisplayString(_ctx.getTaskName(item)), 1)
                                        ]),
                                        "item.taskDate": _withCtx(({ item }) => [
                                          _createTextVNode(_toDisplayString(_ctx.formatDate(item.Date)), 1)
                                        ]),
                                        "item.order": _withCtx(({ item }) => [
                                          _createTextVNode(_toDisplayString(item.OrderNumber), 1)
                                        ]),
                                        "item.actions": _withCtx(({ item }) => [
                                          _createVNode(_component_v_icon, {
                                            small: "",
                                            class: "mr-20",
                                            onClick: ($event: any) => (_ctx.editTask(item))
                                          }, {
                                            default: _withCtx(() => _cache[24] || (_cache[24] = [
                                              _createTextVNode("mdi-pencil")
                                            ])),
                                            _: 2
                                          }, 1032, ["onClick"])
                                        ]),
                                        _: 1
                                      }, 8, ["headers", "items"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                class: "save-btn"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_btn, {
                                    class: "secondary",
                                    variant: "elevated",
                                    onClick: _ctx.closeDialog
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_icon, null, {
                                        default: _withCtx(() => _cache[25] || (_cache[25] = [
                                          _createTextVNode("mdi-close")
                                        ])),
                                        _: 1
                                      }),
                                      _cache[26] || (_cache[26] = _createTextVNode(" cancel"))
                                    ]),
                                    _: 1
                                  }, 8, ["onClick"]),
                                  _createVNode(_component_v_btn, {
                                    class: "primary",
                                    color: "primary",
                                    variant: "elevated",
                                    onClick: _ctx.savePosition,
                                    loading: _ctx.loading
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_icon, null, {
                                        default: _withCtx(() => _cache[27] || (_cache[27] = [
                                          _createTextVNode("mdi-check")
                                        ])),
                                        _: 1
                                      }),
                                      _createTextVNode(" " + _toDisplayString(_ctx.dialogTitle == "Edit Job" ? "Update" : "Save"), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["onClick", "loading"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}